export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Addictologie"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Addictologie"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée en addictologie, avec notre programme à distance de\ncertification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme de formation vous permet d’acquérir des compétences professionnelles, qui s’étendent\nde la prévention d’une consommation à risque au traitement d’addictions graves, chroniques et\ninvalidantes, en passant par différentes formes moins sévères. Il englobe tous les problèmes\nd’addiction, qu’ils soient ou non liés à des substances, et prend en compte les aspects psychiques,\nsomatiques et sociaux."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévention"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurer et coordonner la prévention des divers comportements addictifs"])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostic"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnaître et faire le diagnostic d’un comportement addictif"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comorbidités"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savoir identifier les comorbidités associées à un comportement addictif"])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complications"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer la gravité d’un comportement addictif, ainsi que ses différentes complications"])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise en charge"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer une offre adaptée de soins à la personne addict"])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi-évaluation"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurer le suivi au long cours de la personne addict"])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins psychiatres, médecins addictologues, médecins en santé mentale,\nmédecins de toutes spécialités médicales et chirurgicales, médecins généralistes."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres professionnels de santé"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infirmiers spécialistes en santé mentale, techniciens\nsupérieurs en santé mentale, intervenants en santé mentale, infirmiers généralistes,\npharmaciens, etc."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère\nparfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode\nasynchrone (cours pré-enregistrés) et synchrone (classes virtuelles)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé de professeurs des universités\net d’experts européens en addictologie et en psychiatrie."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 1er novembre au 10 décembre 2024"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’étudiant(e) est soumis(e) à une épreuve de contrôle de\nconnaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en « addictologie », qui est délivré aux étudiant(e)s ayant obtenu une note supérieure ou égale à\n60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de\nl’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. La date limite pour s’inscrire au programme est le 30 octobre 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures\npertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de\nfinaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}